// @font-face {
//   font-family: 'Rubik';
//   font-style: normal;
//   font-weight: normal;
//   src: url('../assets/fonts/Rubik/Rubik-Light.ttf') format('truetype');
// }

// @font-face {
//   font-family: 'Rubik';
//   font-style: normal;
//   font-weight: 600;
//   src: url('../assets/fonts/Rubik/Rubik-Regular.ttf') format('truetype');
// }

// @font-face {
//   font-family: 'Rubik';
//   font-style: normal;
//   font-weight: 700;
//   src: url('../assets/fonts/Rubik/Rubik-Regular.ttf') format('truetype');
// }

// @font-face {
//   font-family: 'Rubik';
//   font-style: normal;
//   font-weight: bold;
//   src: url('../assets/fonts/Rubik/Rubik-Medium.ttf') format('truetype');
// }

// @font-face {
//   font-family: 'Malgun Gothic';
//   font-style: normal;
//   font-weight: normal;
//   src: url('../assets/fonts/Malgun-Gothic/Malgun-Gothic-Regular.ttf')
//     format('truetype');
// }

// @font-face {
//   font-family: 'Malgun Gothic';
//   font-style: normal;
//   font-weight: bold;
//   src: url('../assets/fonts/Malgun-Gothic/Malgun-Gothic-Bold.ttf')
//     format('truetype');
// }

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  height: 5px !important;
  width: 4px !important;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(49, 49, 49, 0.3) !important;
  -webkit-border-radius: 10px !important;
  box-shadow: inset 0 0 6px rgba(49, 49, 49, 0.3) !important;
  border-radius: 10px !important;
}
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px !important;
  -webkit-box-shadow: inset 0 0 6px rgba(#3c4b64, 0.5) !important;
  border-radius: 10px !important;
  background: rgba(203, 227, 236, 0.4) !important;
  box-shadow: inset 0 0 6px rgba(#3c4b64, 0.5) !important;
}
::-webkit-scrollbar-thumb:window-inactive {
  background: $light !important;
}

div.app-body {
  display: flex !important;
  flex-direction: column !important;
  min-height: 100vh !important;
  padding: 0 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.modal-header .btn-close {
  position: absolute !important;
  top: 10px;
  right: 10px;
}

/* Chrome, Safari, Edge, Opera */
input.input-no-spin::-webkit-outer-spin-button,
input.input-no-spin::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input.input-no-spin[type='number'] {
  -moz-appearance: textfield;
}

small {
  font-size: 0.75rem !important;
}
