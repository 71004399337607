.react-datepicker__header {
  background-color: $white !important;
  border: none !important;
}

.react-datepicker__current-month {
  font-size: 0.85rem !important;
}

.react-datepicker__day-names {
  border-bottom: 1px $secondary solid !important;
}

.react-datepicker__day-name {
  font-weight: bold !important;
}
